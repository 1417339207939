<template>
    <v-card>
        <v-card-title>

        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <s-select-definition 
                        v-model="TypeProduction" 
                        label="Tipo Producción"
						:def="1464"
                        @input="changeTypeProduction($event)">

                    </s-select-definition>
                </v-col>                
            </v-row>
            <v-row>
                <v-col cols="2">
                    <s-text v-model="TextVariante"></s-text>
                </v-col>
                <v-col cols="auto">
                    <v-btn 
                        color="primary"
                        @click="SaveVariante()">Guardar</v-btn>
                </v-col>
                <v-col cols="5">
                    <v-data-table  
                        :headers="HeaderVariante"
                        :items="ListVariante"
                        dense
                        v-model="selectVariante">
                        <template v-slot:item.action ="{ item }">
                            <v-row>
                                <v-col>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editVariante(item)">
                                        mdi-pencil
                                    </v-icon>
                                </v-col>
                                <v-col>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="deleteVariante(item)">
                                        mdi-delete
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <s-text v-model="TextEstadoFinal"></s-text>
                </v-col>
                <v-col cols="auto">
                    <v-btn 
                        color="primary"
                        @click="SaveEstadoFinal()">Guardar</v-btn>
                </v-col>
                <v-col cols="5">
                    <v-data-table  
                        :headers="HeaderEstadoFinal"
                        :items="ListEstadoFinal"
                        dense
                        v-model="selectEstadoFinal">
                        <template v-slot:item.action ="{ item }">
                            <v-row>
                                <v-col>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editEstadoFinal(item)">
                                        mdi-pencil
                                    </v-icon>
                                </v-col>
                                <v-col>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="deleteEstadoFinal(item)">
                                        mdi-delete
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
        <!-- <v-dialog
			v-model="dialogVariante"
			max-width="400px"
			height="80px"
			persistent
		>
			<v-card>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<s-text
									v-model="TextVariante"
									label="Valor">
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="warning"
					text
					@click="closeDialogEditParameter()"
				>
					Cancelar
				</v-btn>
				<v-btn
					color="primary"
					text
					@click="save()"
				>
					Guardar
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-dialog
			v-model="dialogEstadoFinal"
			max-width="400px"
			height="80px"
			persistent
		>
			<v-card>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<s-text
									v-model="TextEstadoFinal"
									label="Valor">
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="warning"
					text
					@click="closeDialogEditParameter()"
				>
					Cancelar
				</v-btn>
				<v-btn
					color="primary"
					text
					@click="save()"
				>
					Guardar
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog> -->
    </v-card>
</template>

<script>
    import _typestatefinal from "@/services/FrozenProduction/FrzTypeStateFinal.js";  
    export default {
        data(){
            return {
                HeaderVariante:[
                    { text: "item", value:"ID"},                    
                    { text: "Descripcion", value:"TsfDescription"},
                    { text:"Acción", value: "action"},          
                ],
                HeaderEstadoFinal:[
                    { text: "item", value:"ID"},                    
                    { text: "Descripcion", value:"TsfDescription"},
                    { text:"Acción", value: "action"},            
                ],
                TextVariante: "",
                TextEstadoFinal: "",
                TypeProduction: 1,
                ListVariante: [],
                ListEstadoFinal: [],
                selectEstadoFinal: {},
                selectVariante: {},
                dialogVariante: false,
                dialogEstadoFinal: false
            }
        },
        methods:{
            SaveVariante(){
                let json = {};
                json.TsfDescription = this.TextVariante;
                json.DedID = this.TypeProduction;
                json.Position = 1;
                json.TsfHelper = '';
                json.UsrCreateID = this.$fun.getUserID();
                json.UsrUpdateID = this.$fun.getUserID();
                if(this.selectVariante.TsfID != null)
                {
                    json.TsfID = this.selectVariante.TsfID;
                    json.SecStatus = this.selectVariante.SecStatus;
                    json.TsfStatus = this.selectVariante.TsfStatus;
                }
                _typestatefinal.save(json, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200)
                    {
                        this.$fun.alert("Se registro correctamente", "success");
                        this.GetListVariante();
                        this.TextVariante =  "";
                        this.TextEstadoFinal = "";
                    }
                });
            },
            SaveEstadoFinal(){
                let json = {};
                json.TsfDescription = this.TextEstadoFinal;
                json.DedID = this.TypeProduction;
                json.Position = 2;
                json.UsrCreateID = this.$fun.getUserID();
                json.UsrUpdateID = this.$fun.getUserID();
                if(this.selectEstadoFinal.TsfID != null)
                {
                    json.TsfID = this.selectEstadoFinal.TsfID;
                    json.SecStatus = this.selectEstadoFinal.SecStatus;
                    json.TsfStatus = this.selectEstadoFinal.TsfStatus;
                }
                _typestatefinal.save(json, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200)
                    {
                        this.$fun.alert("Se registro correctamente", "success");
                        this.GetListEstadoFinal();
                        this.TextVariante =  "";
                        this.TextEstadoFinal = "";
                    }
                });
            },
            GetListVariante(){
                _typestatefinal.list({DedID : this.TypeProduction, Position : 1}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.ListVariante = r.data;
                        this.ListVariante.forEach((element, index) => {
                            element.ID = index + 1;
                        });
                        this.selectVariante = {};
                        this.selectEstadoFinal = {};
                    }
                })
            },
            GetListEstadoFinal(){
                _typestatefinal.list({DedID : this.TypeProduction, Position : 2}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.ListEstadoFinal = r.data;
                        this.ListEstadoFinal.forEach((element, index) => {
                            element.ID = index + 1;
                        });
                        this.selectVariante = {};
                        this.selectEstadoFinal = {};
                    }
                })
            },
            changeTypeProduction(item){
                
                this.selectVariante = {};
                this.selectEstadoFinal = {};
                this.ListVariante = [];
                this.ListEstadoFinal = [];
                this.GetListVariante();
                this.GetListEstadoFinal();
            },
            
            editVariante(item)
			{
                
				this.TextVariante = item.TsfDescription;
				this.selectVariante = item;
				// this.dialogVariante = true;
				
			},
            deleteVariante(item)
            {
                item.SecStatus = 0;
                item.TsfStatus = 0;
                this.selectVariante = item;
                this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
                        this.SaveVariante();
                    }
                });
            },
            deleteEstadoFinal(item)
            {
                item.SecStatus = 0;
                item.TsfStatus = 0;
                this.selectEstadoFinal = item;
                this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
                        this.SaveEstadoFinal();
                    }
                });
            },
            editEstadoFinal(item)
			{
                
				this.TextEstadoFinal = item.TsfDescription;
				this.selectEstadoFinal = item;
                
				// this.dialogEstadoFinal = true;
				
			},
            // closeDialogEditParameter()
            // {
            //     this.dialogEstadoFinal = false;
            //     this.dialogVariante = false;
            // }
        },
        created()
        {
            this.GetListVariante();
            this.GetListEstadoFinal();
        }
    }
</script>